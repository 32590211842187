import axios from './http'
/*https://blog.51cto.com/jackiehao/5089232*/

/*影院接口*/
/*登录接口*/
export function Login(params){
    return axios.post("/common/api/login",params)
}
/*获得影院信息*/
export function GetCinemaInfo(params){
    return axios.post("/member/api/view",params)
}

/*修改影院资料*/
export function UpdataCinemaInfo(params){
    return axios.post("/member/api/modify",params)
}

/*添加影厅*/
export function AddHall(params){
    return axios.post("/member/api/addhall",params)
}

/*获得影厅*/
export function GetHallList(params){
    return axios.post("/member/api/halllist",params)
}

/*修改影厅信息*/
export function UpdataHall(params){
    return axios.post("/member/api/edithall ",params)
}

/*删除影厅信息*/
export function DelHall(params){
    return axios.post("/member/api/delhall",params)
}

/*获得影厅报告列表*/
export function getFileList(params){
    return axios.post("/detection/api/lists",params)
}

/*下载影厅报告pdf*/
export function getFilePdf(params){
    return axios.post("/detection/api/download",params)
}

/*获得单个影厅检测结果*/
export function getOneHallResult(params){
    return axios.post("/detection/api/show",params)
}

/*检测员接口*/
/*搜索影院*/
export function SearchCinema(params){
    return axios.post("/member/api/query",params)
}

/*获得影院信息*/
export function GetCinema(params){
    return axios.post("/member/api/choose",params)
}

/*提交影院检测员信息*/
export function SetTestingInfo(params){
    return axios.post("/detection/api/getInfo",params)
}

/*获得被检测影院的影厅列表*/
export function GetOneCinemaList(params){
    return axios.post("/detection/api/query",params)
}

/*提交检测数据*/
export function SetTestingData(params){
    return axios.post("/detection/api/write",params)
}

/*获得检测历史*/
export function GetTestingHistory(params){
    return axios.post("/detection/api/record",params)
}

/*查询已经检查过的影院的影厅*/
export function GetTestingCinemaInfo(params){
    return axios.post("/detection/api/myhall",params)
}

/*查询已经填写的厅信息*/
export function GetOneHall(params){
    return axios.post("/detection/api/getHall",params)
}

/*提交影厅整体报告*/
export function SetAllHall(params){
    return axios.post("/detection/api/submit",params)
}

/*获得工作安排*/
export function GetWorkRecord(params){
    return axios.post("/appoint/api/appoint",params)
}

/*游客接口*/
/*上传图片*/
export function UploadingImg(params){
    return axios.post("/common/api/upload",params)
}

/*投诉接口*/
export function Complaint(params){
    return axios.post("/complain/api/complain",params)
}


/*2022-08-11*/
//复检
export function ChooseFu(params){
    return axios.post("/detection/api/chooseFu",params)
}
