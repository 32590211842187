<template>
  <div class="login">
    <div class="logo">
      <img src="../assets/header_logo.png">
    </div>
<!--    <p>浙江省电影放映协会</p>-->
    <small><slot></slot></small>
    <main>
      <van-cell-group inset>
        <p>账户</p>
        <van-field v-model="user" placeholder="请输入账号"/>
        <p>密码</p>
        <van-field v-model="password" type="password" placeholder="请输入密码" />
      </van-cell-group>

      <van-button type="primary" block color="#5A77FD" round @click="login" :loading="login_loading" loading-text="登录中...">登录</van-button>
    </main>
  </div>
</template>

<script>
import {Button, CellGroup, Field} from "vant";
export default {
  name: "Login",
  components:{
    [Field.name]:Field,
    [CellGroup.name]:CellGroup,
    [Button.name]:Button,
  },
  data(){
    return{
      user:'',
      password:'',
    }
  },
  methods:{
    login(){
      this.$emit("get_user_password",this.user,this.password)
    }
  },
  props:{
    login_loading:{type:Boolean,default:false},
  },

}
</script>

<style scoped>
.login {
  /*min-height: 1536px;*/
  background: url("../assets/Mask group.png") top center / cover no-repeat;
  padding-top: 89px;
}
.login .logo {
  /*width: 155px;*/
  /*height: 155px;*/
  /*background: #C4C4C4;*/
  /*border-radius: 78px;*/
  margin: 0 auto 14px;
  text-align: center;
}

.login .logo img{
  width: 360px;
}

.login>p {
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #000000;
  line-height: 47px;
}

.login>small{
  margin: 10px auto 90px;
  width: 750px;
  display: block;
  text-align: center;
  font-size: 20px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 600;
}
.login main {
  width: calc(618px);
  height: calc(534px);
  background: #FFFFFF;
  box-shadow: 0px 7px 31px 1px rgba(189, 224, 243, 0.51);
  border-radius: 47px;
  margin: 0 auto;
  padding: 85px 36px 102px;
}
.login main>>>.van-hairline--top-bottom::after,
.login main>>>.van-hairline-unset--top-bottom::after {
  border: none;
}
.login main>>>.van-field {
  height: 86px;
  background: #EFF8FD;
  border-radius: 41px;
  opacity: 0.87;
  padding: 23px 43px;
  font-size: 28px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
}
.login main p {
  width: 618px;
  height: 39px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #4D6080;
  line-height: 33px;
}
.login main p:nth-of-type(1) {
  text-align: left;
  margin: 0 0 22px;
}
.login main p:nth-of-type(1)::before {
  content: "";
  width: 34px;
  height: 39px;
  background-image: url("../assets/user.png");
  background-repeat: no-repeat;
  background-position: 0 2px;
  background-size: 34px;
  float: left;
  margin-right: 21px;
}
.login main p:nth-of-type(2) {
  margin: 48px 0 13px;
}
.login main p:nth-of-type(2)::before {
  content: "";
  width: 34px;
  height: 39px;
  background-image: url("../assets/password.png");
  background-repeat: no-repeat;
  background-position: 0 2px;
  background-size: 34px;
  float: left;
  margin-right: 21px;
}
.login>>>.van-field__value{
  height: 100%;
  margin: 0;
  padding: 0;
}
>>>.van-field__body{
  height: 100%;
}
.login main button {
  width: 586px;
  height: 86px;
  border-radius: 41px;
  opacity: 0.87;
  margin: 112px auto 0;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
}

</style>

